<template lang="pug">
    b-container
        b-navbar
            b-navbar-nav
                b-nav-item-dropdown(text="Go To")
                    b-dropdown-item Dashboard
                    b-dropdown-item Contact Log
                    b-dropdown-item Case Tracking
                    b-dropdown-item Workload Tracking
            b-navbar-nav.ml-auto
                b-nav-item-dropdown(:text="currentRoleText")
                    b-dropdown-group(header="Switch to:")
                        b-dropdown-item General Supervisor (GS)
                        b-dropdown-item Clinical Supervisor (CS)
                        b-dropdown-item Programme Head (PH)
                        b-dropdown-item Team Leaders (TL)
                        b-dropdown-item Centre Admin
        router-view
</template>
<script>
export default {
    data(){
        return {
            currentRole: "General Supervisor (GS)"
        }
    },
    computed:{
        currentRoleText(){
            return "Logged in as Emil - " + this.currentRole

        }
    }
}
</script>
