import Vue from 'vue'
import App from './App.vue'
import Dashboard from './dashboard.vue'
import Vuex from 'vuex'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCal from 'vue-cal';
import VueRouter from 'vue-router'

import './scss/app.scss';
import 'vue-cal/dist/vuecal.css'
Vue.use(VueRouter)
Vue.use(Vuex);
Vue.component('vue-cal',VueCal);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

const store = new Vuex.Store({
  state: {
    msal: null
  },
  mutations:{
    msal(state,msal){
      state.msal = msal;
    }
  }
  
});

Vue.config.productionTip = false

const router = new VueRouter({
  routes: [
    { path: '/', component: Dashboard }
  ]
})

new Vue({
  render: h => h(App),
  store,router
}).$mount('#app')
