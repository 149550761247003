<template lang="pug">
  b-container
    b-card
      p.lead Youth Go Dashboard
      p 
        strong Projected Number of Hours: 
        span 48
    b-card 
      vue-cal(
        activeView="month"
        start-week-on-sunday
        disable-date-prototypes
        :timeFrom="timebounds.start" 
        :timeTo="timebounds.end" 
        :disableViews="['years', 'year']" 
        :time-cell-height="80"
        style="min-height: 70vh"
        :events="events"
        events-on-month-view 
      )
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      loading:true,
      refreshing:false,
      activeTab: 0,
      selectedFilter:null,
      selectedSplit:'Location',
      hideWeekends: false,
      showNonWorkhours: false,
      viewMode: "week"
    }
  },
  computed:{
    timebounds(){
      return this.showNonWorkhours?{start:0, end:24*60}:{start:7*60, end:21*60}
    },
    events(){
      return[
        {
            start: '2022-04-05 08:00',
            end: '2022-04-05 12:00',
            title: 'Greenridge',
            content:'<i class="bi bi-person-fill"></i> Tan',
            class: 'bg-cal5 text-light'
        },
        {
            start: '2022-04-06 14:00',
            end: '2022-04-06 18:00',
            title: 'Petir',
            content:'<i class="bi bi-person-fill"></i> Pang',
            class: 'bg-cal2 text-light'
        },
        {
            start: '2022-04-07 14:00',
            end: '2022-04-07 18:00',
            title: 'Fajar',
            content:'<i class="bi bi-person-fill"></i> Tan',
            class: 'bg-cal2 text-light'
        },
        {
            start: '2022-04-13 08:00',
            end: '2022-04-13 12:00',
            title: 'Bangkit',
            content:'<i class="bi bi-person-fill"></i> Tan',
            class: 'bg-cal1 text-light'
        },
        {
            start: '2022-04-14 14:00',
            end: '2022-04-14 18:00',
            title: 'Petir',
            content:'<i class="bi bi-person-fill"></i> Pang',
            class: 'bg-cal2 text-light'
        },
        {
            start: '2022-04-15 14:00',
            end: '2022-04-15 18:00',
            title: 'Fajar',
            content:'<i class="bi bi-person-fill"></i> Tan',
            class: 'bg-cal2 text-light'
        },
        {
            start: '2022-04-18 08:00',
            end: '2022-04-18 12:00',
            title: 'Bangkit',
            content:'<i class="bi bi-person-fill"></i> Tan',
            class: 'bg-cal1 text-light'
        },
        {
            start: '2022-04-19 14:00',
            end: '2022-04-19 18:00',
            title: 'Petir',
            content:'<i class="bi bi-person-fill"></i> Pang',
            class: 'bg-cal2 text-light'
        },
        {
            start: '2022-04-20 14:00',
            end: '2022-04-20 18:00',
            title: 'Fajar',
            content:'<i class="bi bi-person-fill"></i> Tan',
            class: 'bg-cal2 text-light'
        },
        {
            start: '2022-04-28 08:00',
            end: '2022-04-28 12:00',
            title: 'Bangkit',
            content:'<i class="bi bi-person-fill"></i> Tan',
            class: 'bg-cal1 text-light'
        },
        {
            start: '2022-04-26 14:00',
            end: '2022-04-26 18:00',
            title: 'Petir',
            content:'<i class="bi bi-person-fill"></i> Pang',
            class: 'bg-cal2 text-light'
        },
        {
            start: '2022-04-29 14:00',
            end: '2022-04-29 18:00',
            title: 'Senja',
            content:'<i class="bi bi-person-fill"></i> Tan',
            class: 'bg-cal4 text-light'
        }
      ]
    }

  }
}
</script>

